<template>
  <div class="position-container">
    <div class="home-position-slider" :class="selectedEvent.team === 'home'? 'mid' : 'hidden'">
      <svg viewBox="0 0 568 344" xmlns="http://www.w3.org/2000/svg">
        <g>
          <polygon points="0, 0 520, 0 568, 160 520, 344 0, 344" fill="url(#svg_1)"/>
        </g>
        <defs>
          <linearGradient y2="0" x2="1" y1="0" x1="0" id="svg_1">
            <stop offset="0" stop-opacity="0.2" stop-color="#000000"/>
            <stop offset="1" stop-opacity="0.65" stop-color="#000000"/>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="away-position-slider" :class="selectedEvent.team === 'away'? 'mid' : 'hidden'">
      <svg viewBox="0 0 568 344" xmlns="http://www.w3.org/2000/svg">
        <g>
          <polygon points="0, 160 28, 0 568, 0 568, 344 28, 344" fill="url(#svg_2)"/>
        </g>
        <defs>
          <linearGradient y2="0" x2="1" y1="0" x1="0" id="svg_2">
            <stop offset="0" stop-opacity="0.65" stop-color="#000000"/>
            <stop offset="1" stop-opacity="0.2" stop-color="#000000"/>
          </linearGradient>
        </defs>
      </svg>
    </div>

    <div class="home-attack-container" v-if="selectedEvent.team === 'home'">
      <span class="possession"> Possession </span> <br>
      <span class="team-name">{{matchTeam.teams.home.name.toUpperCase()}}</span>
    </div>

    <div class="away-attack-container" v-if="selectedEvent.team === 'away'">
      <span class="possession"> Possession </span> <br>
     <span class="team-name">{{matchTeam.teams.away.name.toUpperCase()}}</span>
    </div>

  </div>
</template>

<script>

export default {
  name: "AttackPosition",
  props: {
    selectedEvent: Object,
    matchTeam: Object
  }
}
</script>

<style scoped>

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.home-attack-container {
  position: absolute;
  align-self: center;
  left: 25%;
  font-size: 20px;
  color: #e8e8e8;
  transition: .25s;
  transform: translateX(-40%);
}

.away-attack-container {
  position: absolute;
  align-self: center;
  text-align: right;
  right: 25%;
  font-size: 20px;
  color: #e8e8e8;
  animation: blinker 1s linear infinite;
  transition: .25s;
  transform: translateX(40%);
}

.home-position-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: .25s;
  transform: translateX(-100%);
}

.away-position-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  transition: .25s;
  transform: translateX(100%);
}

.home-position-slider.hidden {
  transform: translateX(-100%);
}

.home-position-slider.low {
  transform: translateX(-44%);
}

.home-position-slider.mid {
  transform: translateX(-40%);
}

.home-position-slider.high {
  transform: translateX(-15%);
}

.away-position-slider.hidden {
  transform: translateX(100%);
}

.away-position-slider.low {
  transform: translateX(44%);
}

.away-position-slider.mid {
  transform: translateX(40%);
}

.away-position-slider.high {
  transform: translateX(15%);
}

.possession {
  font-weight: 300;
  font-size: 20px;
}

.team-name {
  color: white;
  font-weight: 500;
}

</style>