<template>
  <div class="position-container">
    <div class="home-goal-section home-goal-animation" v-if="selectedEvent.team === 'home'">
      <div class="goal-container">

        <div class="jersery">
        </div>

        <div class="goal-info">
          <div class="goal-section">
            <div class="result">
              <div class="home-plus">
                <span v-if="selectedEvent.team === 'home'&& selectedEvent.points === 1 ">+1</span>
                <span v-if="selectedEvent.team === 'home'&& selectedEvent.points === 2 ">+2</span>
                <span v-if="selectedEvent.team === 'home'&& selectedEvent.points === 3 ">+3</span>
              </div>
              <div class="point">
                {{matchTeam.result.home}}
              </div>
              <div class="dot">
                :
              </div>
              <div class="point away-point">
                {{matchTeam.result.away}}
              </div>
              <div class="away-plus">
                <span v-if="selectedEvent.team === 'away'&& selectedEvent.points === 1 ">+1</span>
                <span v-if="selectedEvent.team === 'away'&& selectedEvent.points === 2 ">+2</span>
                <span v-if="selectedEvent.team === 'away'&& selectedEvent.points === 3 ">+3</span>
              </div>
            </div>
            <hr :class="selectedEvent.team === 'home' ? 'home-goal' : 'away-goal'">
            <div class="team-name">
              {{ matchTeam.teams.home.name }}
            </div>
          </div>

          <div class="goal-count">
            <span v-if="selectedEvent.points === 2 && selectedEvent.team === 'home'" class="goal-stats-string"> {{matchDetails.twopointers.value.home.split("/")[0]}} / {{matchDetails.twopointers.value.home.split("/")[2]}} </span>
            <span v-if="selectedEvent.points === 3 && selectedEvent.team === 'home'" class="goal-stats-string"> {{matchDetails.threepointers.value.home.split("/")[0]}} / {{matchDetails.threepointers.value.home.split("/")[2]}} </span>


            <span v-if="selectedEvent.points === 1" class="goal-string">1 POINT SHOT</span>
            <span v-if="selectedEvent.points === 2" class="goal-string">2 POINT SHOTS</span>
            <span v-if="selectedEvent.points === 3" class="goal-string">3 POINT SHOTS</span>
          </div>
        </div>
      </div>
    </div>

    <div class="away-goal-section blink" v-if="selectedEvent.team === 'away'">
      <div class="goal-container">

        <div class="jersery">
        </div>

        <div class="goal-info">
          <div class="goal-section">
            <div class="result">
              <div class="home-plus">
                <span v-if="selectedEvent.team === 'home'&& selectedEvent.points === 1 ">+1</span>
                <span v-if="selectedEvent.team === 'home'&& selectedEvent.points === 2 ">+2</span>
                <span v-if="selectedEvent.team === 'home'&& selectedEvent.points === 3 ">+3</span>
              </div>
              <div class="point">
                {{matchTeam.result.home}}
              </div>
              <div class="dot">
                :
              </div>
              <div class="away-point">
                {{matchTeam.result.away}}
              </div>
              <div class="away-plus">
                <span v-if="selectedEvent.team === 'away'&& selectedEvent.points === 1 ">+1</span>
                <span v-if="selectedEvent.team === 'away'&& selectedEvent.points === 2 ">+2</span>
                <span v-if="selectedEvent.team === 'away'&& selectedEvent.points === 3 ">+3</span>
              </div>
            </div>
            <hr :class="selectedEvent.team === 'home' ? 'home-goal' : 'away-goal'">
            <div class="team-name">
              {{ matchTeam.teams.away.name }}
            </div>
          </div>

          <div class="goal-count">
            <span v-if="selectedEvent.points === 2 && selectedEvent.team === 'away'" class="goal-stats-string"> {{matchDetails.twopointers.value.away.split("/")[0]}} / {{matchDetails.twopointers.value.away.split("/")[2]}} </span>
            <span v-if="selectedEvent.points === 3 && selectedEvent.team === 'away'" class="goal-stats-string"> {{matchDetails.threepointers.value.away.split("/")[0]}} / {{matchDetails.threepointers.value.away.split("/")[2]}} </span>

            <span v-if="selectedEvent.points === 1" class="goal-string">1 POINT SHOT</span>
            <span v-if="selectedEvent.points === 2" class="goal-string">2 POINT SHOTS</span>
            <span v-if="selectedEvent.points === 3" class="goal-string">3 POINT SHOTS</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GoalPosition",
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    matchDetails: Object
  }
}
</script>

<style scoped>

.blink {
  animation: reveal .5s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(100% 0 100% 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

.home-goal-animation {
  animation: home-goal-anim .5s forwards;
}

@keyframes home-goal-anim {
  from {
    clip-path: inset(100% 100% 100% 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

hr {
  height: 1px;
  background-color: dodgerblue;
  margin: 2px 0 0 0;
  border: none;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.home-goal-section {
  position: absolute;
  top: 20%;
  left: 70%;
  place-items: center;
  width: 100%;
}

.away-goal-section {
  position: absolute;
  top: 20%;
  left: 15%;
  place-items: center;
  width: 100%;
}

.goal-container {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 180px;
  height: 88px;
  background-color: white;
}

.point {
  display: flex;
  justify-content: center;
  place-items: center;
  min-width: 30px;
  height: 16px;
  border-radius: 3px;
  color: white;
  font-weight: bolder;
  background-color: #002171;
}

.away-point {
  display: flex;
  justify-content: center;
  place-items: center;
  background-color: #ff0000;
  min-width: 30px;
  border-radius: 3px;
  font-weight: bolder;
  height: 16px;
}

.home-plus {
  padding: 0 2px;
  color: #002171;
  animation: .7s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

.away-plus {
  padding: 0 2px;
  color: #ff0000;
  animation: .7s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.jersery {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 50px;
  height: 100px;
}

.goal-info {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  height: 90%;
}

.goal-section {
  display: flex;
  flex-direction: column;
}

.result {
  display: flex;
  place-items: center;
  color: white;
  font-weight: 500;
}

.team-name {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 300;
}

.home-goal {
  background-color: #002171;
}

.away-goal {
  background-color: #ff0000;
}

.dot {
  color: black;
  font-size: 13px;
  padding: 0 5px;
}

.goal-count {
  display: flex;
  flex-direction: column;
}

.goal-string {
  font-weight: 300;
}

.goal-stats-string {
  font-weight: 500;
}

</style>