<template>
 <div class="position-container">
   <div v-if="matchTeam.status._id === 30" class="timeout-container">
     <div class="half-time-string">
       HALF TIME
     </div>
   </div>

   <div v-if="matchTeam.status._id === 100" class="timeout-container">
     <div class="half-time-string">
       MATCH ENDED
     </div>
   </div>
 </div>
</template>

<script>
export default {
  name: "HalfTimeAndEnded",
  props: {
    matchTeam: Object
  }
}
</script>

<style scoped>

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.timeout-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  place-items: center;
  min-width: 120px;
  height: 90px;
  background-color: white;
  border-radius: 2px;
}

.half-time-string {
  font-weight: 500;
  font-size: 14px;
  color: red;
}

</style>