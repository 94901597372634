export const MAX_EVENT_COUNT = 5;
export const UNDEFINED_SECONDS = -1;

//sorted by priority
export const EVENT_TYPES = [
    'goal',
    'possession',
    'attempt_missed',
    'rebound',
    'timeout',
    'foul',
    'free_throws_awarded'
];

export const EVENT_STATUSES = {
    0: {text: 'Maç Başlamadı', isLive: false, time: -1},
    31: {text: 'Devre Arası', isLive: false, time: 45},
    70: {text: 'Maç İptal', isLive: false, time: -1},
    100: {text: 'Maç Sona Erdi', isLive: false, time: -1},
}
