<template>
    <div>
        <p style="font-size: 1.02rem">Match status: <b>{{ matchStatus || '-' }}</b></p>
        <p style="font-size: 1.02rem">Match time: <b>{{ matchTime || '-' }}</b></p>
        <h3 class="mt-5">Selected Event</h3>
        <table style="width: 100%">
            <thead>
            <tr>
                <th>Event _id</th>
                <th>Event name</th>
                <th>Event type</th>
                <th>points</th>
                <th>Seconds</th>
                <th>Team</th>
                <th>Updated Date/Time</th>
            </tr>
            </thead>
            <tbody>
            <tr v-if="selectedEvent">
                <td>{{ selectedEvent._id }}</td>
                <td>{{ selectedEvent.name }}</td>
                <td>{{ selectedEvent.type }}</td>
                <td>{{ selectedEvent.points }}</td>
                <td>{{ selectedEvent.seconds | formatSeconds }}</td>
                <td>{{ selectedEvent.team }}</td>
                <td>{{ selectedEvent.updated_uts | formatDateTime }}</td>
            </tr>
            </tbody>
        </table>

        <h3 class="mt-5">Filtered Events</h3>
        <table style="width: 100%">
            <thead>
            <tr>
                <th>Event _id</th>
                <th>Event name</th>
                <th>Event type</th>
                <th>points</th>
                <th>Seconds</th>
                <th>Team</th>
                <th>Updated Date/Time</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(e, ei) in filteredEvents" :key="ei">
                <td>{{ e._id }}</td>
                <td>{{ e.name }}</td>
                <td>{{ e.type }}</td>
                <td>{{ e.points }}</td>
                <td>{{ e.seconds | formatSeconds }}</td>
                <td>{{ e.team }}</td>
                <td>{{ e.updated_uts | formatDateTime }}</td>
            </tr>
            </tbody>
        </table>

        <h3 class="mt-5">All Events</h3>
        <table style="width: 100%">
            <thead>
            <tr>
                <th>Event _id</th>
                <th>Event name</th>
                <th>Event type</th>
                <th>points</th>
                <th>Seconds</th>
                <th>Team</th>
                <th>Updated Date/Time</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(e, ei) in allEvents" :key="ei">
                <td>{{ e._id }}</td>
                <td>{{ e.name }}</td>
                <td>{{ e.type }}</td>
                <td>{{ e.points }}</td>
                <td>{{ e.seconds | formatSeconds }}</td>
                <td>{{ e.team }}</td>
                <td>{{ e.updated_uts | formatDateTime }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import * as dayjs from "dayjs";

export default {
    name: "EventMonitor",
    props: {
        allEvents: Array,
        filteredEvents: Array,
        selectedEvent: Object,
        matchTime: Number,
        matchStatus: String,
    },
    filters: {
        formatDateTime: function (value) {
            return dayjs.unix(value).format('DD/MM/YYYY - HH:mm:ss');
        },
        formatSeconds: function (value) {
            if (value === -1) return null;
            return `${(value / 60).toFixed(0)}:${(value % 60)}`
        }
    },
}
</script>

<style scoped>
td {
    text-align: center;
}

tr:hover {
    background-color: #0a6cce22;
}
</style>
