<template>
  <div class="position-container">
    <div class="home-missed-section" v-if="selectedEvent.team === 'home'">
      <div class="missed-container">

        <div class="jersery">
        </div>

        <div class="missed-info">
          <div class="missed-string" >
            <div :class="selectedEvent.team === 'home' ? 'home-missed' : 'away-missed'" v-if="selectedEvent.points === 1" class="missed">
              1 PT MISSED
            </div>
            <div :class="selectedEvent.team === 'home' ? 'home-missed' : 'away-missed'" v-if="selectedEvent.points === 2" class="missed">
              2 PT MISSED
            </div>
            <div :class="selectedEvent.team === 'home' ? 'home-missed' : 'away-missed'" v-if="selectedEvent.points === 3" class="missed">
              3 PT MISSED
            </div>
            <hr :class="selectedEvent.team === 'home' ? 'home-missed' : 'away-missed'">
            <div class="team-name">
              {{matchTeam.teams.home.name}}
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="away-missed-section" v-if="selectedEvent.team === 'away'">
      <div class="missed-container">

        <div class="jersery">
        </div>

        <div class="missed-info">
          <div class="missed-string" >
            <div :class="selectedEvent.team === 'away' ? 'away-missed' : 'home-missed'" v-if="selectedEvent.points === 1" class="missed">
              1 PT MISSED
            </div>
            <div :class="selectedEvent.team === 'away' ? 'away-missed' : 'home-missed'" v-if="selectedEvent.points === 2" class="missed">
              2 PT MISSED
            </div>
            <div :class="selectedEvent.team === 'away' ? 'away-missed' : 'home-missed'" v-if="selectedEvent.points === 3" class="missed">
              3 PT MISSED
            </div>
            <hr :class="selectedEvent.team === 'away' ? 'away-missed' : 'home-missed'">
            <div class="team-name">
              {{matchTeam.teams.away.name}}
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MissedPosition",
  props: {
    selectedEvent: Object,
    matchTeam: Object
  }
}
</script>

<style scoped>

hr {
  height: 1px;
  background-color: #000058;
  margin: 2px 0 0 0;
  border: none;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.home-missed-section {
  position: absolute;
  top: 20%;
  left: 65%;
  place-items: center;
  width: 100%;
}

.away-missed-section {
  position: absolute;
  top: 10%;
  left: 15%;
  place-items: center;
  width: 100%;
}

.missed-container {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 180px;
  padding: 5px;
  background-color: white;
}

.jersery {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 50px;
}

.missed-info {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  height: 90%;
}

.missed-string {
  display: flex;
  flex-direction: column;
}

.team-name {
  font-size: 12px;
  font-weight: 300;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.home-missed {
  background-color: #002171;
}

.away-missed {
  background-color: red;
}

.missed {
  border-radius: 4px;
  color: white;
  font-weight: 500;
  width: fit-content;
  padding: 0 5px;
}

</style>