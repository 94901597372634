<template>
  <div class="general-stats-section">
    <div class="general-container">

      <div class="general-statistics">
        <div class="general-statistics-section">
          <div>
            GENERAL STATS
          </div>
        </div>

        <hr>
      </div>

      <div class="info-container">

        <!-- FOULS -->

        <div class="number-container">
          <span class="home-number">{{matchDetails[129].value.home}}</span>
          <span class="font-size" style="font-weight: 300">FOULS</span>
          <span class="away-number">{{matchDetails[129].value.away}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: matchDetails[129].value.home}">
          </div>
          <div class="away-percent" :style="{flex: matchDetails[129].value.away}">
          </div>
        </div>

        <!-- REBOUNDS -->

        <div class="number-container">
          <span class="home-number">{{matchDetails[1067].value.home}}</span>
          <span class="font-size" style="font-weight: 300">REBOUNDS</span>
          <span class="away-number">{{matchDetails[1067].value.away}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: matchDetails[1067].value.home}">
          </div>
          <div class="away-percent" :style="{flex: matchDetails[1067].value.away}">
          </div>
        </div>

        <!-- TIMEOUTS -->

        <div class="number-container">
          <span class="home-number">{{matchDetails[1098].value.home}}</span>
          <span class="font-size" style="font-weight: 300">TIMEOUTS</span>
          <span class="away-number">{{matchDetails[1098].value.away}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: matchDetails[1098].value.home}">
          </div>
          <div class="away-percent" :style="{flex: matchDetails[1098].value.away}">
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "GeneralStats",
  props: {
    matchDetails: Object
  }
}

</script>

<style scoped>

hr {
  height: 1.5px;
  background-color: #dedede;
  border: none;
}

.general-statistics {
  display: flex;
  flex-direction: column;
}

.general-stats-section {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.general-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  z-index: 2;
}

.general-statistics-section {
  font-weight: bolder;
  font-size: 12px;
  padding: 10px 0 3px 0;
}

.info-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 6px;
}

.number-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.home-percent{
  display: flex;
  place-items: center;
  justify-content: space-between;
  width: 100%;
}

.progress{
  display: flex;
  height: 3px;
  background: darkblue;
}

.away-percent {
  display: flex;
  height: 3px;
  background: red;
}

.home-number {
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 12px;
  color: darkblue;
}

.away-number {
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 12px;
  color: red;
}

</style>