<template>
  <div class="position-container">
    <div class="timeout-section">
      <div v-if="selectedEvent.type === 'timeout'" class="timeout-container">
        <div>
          <span :class="selectedEvent.team === 'home' ? 'home-timeout' : 'away-timeout'" v-if="selectedEvent.type === 'timeout'">TIMEOUT</span>
          <span v-else-if="selectedEvent._id === 941987030">TIMEOUT OVER</span>
        </div>
        <div class="team-name" v-if="selectedEvent.team === 'home'">
          {{matchTeam.teams.home.name}}
        </div>
        <div class="team-name" v-else-if="selectedEvent.team === 'away'">
          {{matchTeam.teams.away.name}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TimeoutPosition",
  props: {
    selectedEvent: Object,
    matchTeam: Object
  },
}
</script>

<style scoped>

@keyframes cssAnimation {
  to {
    width:0;
    height:0;
    top: 0;
    overflow:hidden;
    padding: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

.home-timeout {
  color: white;
  padding: 0 5px;
  font-weight: bolder;
  font-size: 14px;
  border-radius: 3px;
  background-color: #002171;
}

.away-timeout {
  color: white;
  padding: 0 5px;
  font-weight: bolder;
  font-size: 14px;
  border-radius: 3px;
  background-color: #ff0000;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  animation: cssAnimation 0s ease-in 5s forwards, 1s fadeIn;
}

.timeout-section {
  position: absolute;
  align-self: center;
  display: flex;
  justify-content: center;
  place-items: center;
  width: 100%;
}

.timeout-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  place-items: center;
  min-width: 120px;
  height: 90px;
  background-color: white;
  border-radius: 2px;
}


.team-name {
  display: flex;
  justify-content: center;
  place-items: center;
  font-weight: normal;
  font-size: 13px;
}


</style>