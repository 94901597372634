<template>
  <div class="position-container">
    <div class="home-foul-section blink" v-if="selectedEvent.team === 'home'">
      <div class="foul-container">

        <div class="jersery">
        </div>

        <div class="foul-info">
          <div class="foul-personel">
            <div class="personel" :class="selectedEvent.team === 'home' ? 'home-foul' : 'away-foul'">
              FOUL | PERSONAL
            </div>
            <hr :class="selectedEvent.team === 'home' ? 'home-foul' : 'away-foul'">
            <div class="team-name">
              {{matchTeam.teams.home.name}}
            </div>
          </div>

          <div class="foul-count">
            <span> {{matchDetails[129].value[selectedEvent.team]}} </span>
            <span v-if="matchDetails[129].value.home === 1" class="foul-string">FOUL</span>
            <span v-else class="foul-string">FOULS</span>
          </div>

        </div>
      </div>
    </div>

    <div class="away-foul-section blink" v-if="selectedEvent.team === 'away'">
      <div class="foul-container">

        <div class="jersery">
        </div>

        <div class="foul-info">
          <div class="foul-personel">
            <div class="personel" :class="selectedEvent.team === 'away' ? 'away-foul' : 'home-foul'">
              FOUL | PERSONAL
            </div>
            <hr :class="selectedEvent.team === 'home' ? 'home-foul' : 'away-foul'">
            <div class="team-name">
              {{matchTeam.teams.home.name}}
            </div>
          </div>

          <div class="foul-count">
            <span> {{matchDetails[129].value[selectedEvent.team] }} </span>
            <span v-if="matchDetails[129].value.away === 1" class="foul-string">FOUL</span>
            <span v-else class="foul-string">FOULS</span>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "FoulPosition",
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    matchDetails: Object
  }
}

</script>

<style scoped>

.blink {
  animation: reveal .5s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(0 100% 0 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

hr {
  height: 1px;
  background-color: red;
  margin: 2px 0 0 0;
  border: none;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.home-foul-section {
  position: absolute;
  top: 10%;
  left: 10%;
  place-items: center;
  width: 100%;
}

.away-foul-section {
  position: absolute;
  top: 10%;
  left: 70%;
  place-items: center;
  width: 100%;
}

.foul-container {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 180px;
  height: 88px;
  background-color: white;
}

.jersery {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 50px;
  height: 100px;
}

.foul-info {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  height: 90%;
}

.foul-personel {
  display: flex;
  flex-direction: column;
}

.team-name {
  font-size: 12px;
  font-weight: 300;
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.personel {
  border-radius: 4px;
  color: white;
  font-weight: 500;
  width: fit-content;
  padding: 0 5px;
}

.home-foul {
  background-color: #002171;
}

.away-foul {
  background-color: red;
}

.foul-count {
  display: flex;
  flex-direction: column;
}

.foul-string {
  font-weight: 300;
}



</style>