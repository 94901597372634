<template>
  <div class="day-and-set-info-section">
    <div class="general-container">



      <div class="day-section">
        <div class="man-or-women-container">
          {{statsCardsUpInfo.tournament.name}}
        </div>
        <hr>
        <div class="day-info">
          {{statsCardsUpInfo.match._dt.date}}
        </div>
      </div>

      <counter v-if="matchTeam.status._id === 0" :match-team="matchTeam"></counter>


      <div class="match-result-section">
        <div class="match-result-container">
          <div class="periods-info">
            <span v-if="selectedEvent.type === 'timeout'"> {{selectedEvent.type.toUpperCase()}} </span>
          </div>
          <div class="match-result">
            <div class="result-box">
              <span class="points"> {{ matchTeam.result.home }} </span>
              <span class="team-name"> {{matchTeam.teams.home.abbr}} </span>
            </div>
            <span class="dot"> : </span>
            <div class="result-box">
              <span class="points"> {{ matchTeam.result.away }} </span>
              <span class="team-name"> {{matchTeam.teams.away.abbr}} </span>
            </div>
          </div>
        </div>
      </div>

      <hr>


      <div class="fv-seciton">
        <v-img src="@/assets/lmt/img-label-fv@2x.png" class="fv-img" contain/>
      </div>
    </div>
  </div>
</template>

<script>
import Counter from "../Counter";
import dayjs from "dayjs";

export default {
  name: "MatchResult",
  components: {
    Counter
  },
  props: {
    matchTeam: Object,
    selectedEvent: Object,
    statsCardsUpInfo: Object
  },
  methods: {
    formatDate(date) {
      return dayjs(date).format('DD/MM/YYYY - HH:mm')
    },
  }
}
</script>

<style scoped>

hr {
  height: 1.5px;
  background-color: #dedede;
  border: none;
}

.day-and-set-info-section {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.general-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  z-index: 2;
}

.day-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 8px 0;
}

.match-result-section {
  display: flex;
  justify-content: center;
  width: 100%;
  place-items: center;
  flex: 1;
}

.match-result {
  display: flex;
  justify-content: center;
}

.periods-info {
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
  font-size: 13px;
}

.points {
  display: flex;
  justify-content: center;
  place-items: center;
  background-color: #ff0000;
  min-width: 35px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  border-radius: 3px;
}

.result-box {
  display: flex;
  flex-direction: column;
}

.dot {
  margin: 0 10px;
  font-size: 20px;
  font-weight: bold;
}

.team-name {
  font-weight: normal;
  display: flex;
  justify-content: center;
}

.fv-seciton {
  display: grid;
  place-items: center;
  justify-content: center;
}

.fv-img {
  width: 60%;
  height: 70%;
}

</style>