<template>
  <div>
    <v-row>
      <v-col cols="12">
        <div class="widget-container">
          <div class="lmt-wrapper">
            <div class="lmt-pitch">
              <!--CONTENT Z-2-->
              <div class="fv-lmt-content-wrapper">
                <position-indicators :selected-event="selectedEvent" :match-team="matchTeam"
                                     :match-details="matchDetails" :statsCardsUpInfo="statsCardsUpInfo"/>
              </div>

              <div class="set-info-section" v-if="matchTeam.status._id !== 0 && matchTeam.status._id !== 100">
                <div class="set-info-container">
                  <div v-if="matchTeam.status._id !== 100 && this.homeFoul" class="foul-info-container">
                    <span v-if="this.homeFoul === '1'">{{ this.homeFoul }} FOUL</span>
                    <span v-else>{{ this.homeFoul }} FOULS</span>
                  </div>

                  <div v-if="matchTeam.status._id !== 100" class="set-info">
                    <div v-if="matchTeam.status._id === 13">1st</div>
                    <div v-if="matchTeam.status._id === 30">BRK</div>
                    <div v-if="matchTeam.status._id === 14">2nd</div>
                    <div v-if="matchTeam.status._id === 15">3rd</div>
                    <div v-if="matchTeam.status._id === 16">4th</div>
                    <div v-if="matchTeam.status._id === 100">Match Ended</div>
                  </div>

                  <div v-if="matchTeam.status._id !== 100 && this.awayFoul" class="foul-info-container">
                    <span v-if="this.awayFoul === '1'"> {{ this.awayFoul }} FOUL </span>
                    <span v-else>{{ this.awayFoul }} FOULS</span>
                  </div>
                </div>
              </div>


              <div class="lmt-content">
                <div class="lmt-pitch-wrapper">
                  <div class="lmt-pitch-color">
                    <div class="lmt-pitch-side-area  lmt-basketball-pitch-bo-l srm-is-top"></div>
                    <div class="lmt-pitch-side-area  lmt-basketball-pitch-bo-l srm-is-btm"></div>
                    <div class="lmt-pitch-side-area  lmt-basketball-pitch-bo-s srm-is-left"></div>
                    <div class="lmt-pitch-side-area  lmt-basketball-pitch-bo-s srm-is-right"></div>
                  </div>


                  <div class="sr-lmt-basketball-pitch__pitch-dimensions">
                    <div class="sr-lmt-basketball-pitch__half-pitch srm-is-left">
                      <div class="sr-lmt-basketball-pitch__free-throw-wrap srm-is-left">
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-border srm-is-top srm-left-edge srm-branding srm-def-bg-clr"></div>
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-lane srm-left-edge srm-branding srm-is-transparent"></div>
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-border srm-is-btm srm-left-edge srm-branding srm-def-bg-clr"></div>
                      </div>
                    </div>
                    <div class="sr-lmt-basketball-pitch__half-pitch srm-is-right">
                      <div class="sr-lmt-basketball-pitch__free-throw-wrap srm-is-right">
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-border srm-is-top srm-right-edge srm-branding srm-def-bg-clr"></div>
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-lane srm-right-edge srm-branding srm-is-transparent"></div>
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-border srm-is-btm srm-right-edge srm-branding srm-def-bg-clr"></div>
                      </div>
                    </div>
                  </div>


                  <div class="lmt-basketball-pitch-texture"
                       style="background-image: url('https://widgets.sir.sportradar.com/assets/167ba7abdf87f0b32bb4d8324a4c273d.png');"></div>


                  <div class="sr-lmt-basketball-pitch__pitch-dimensions">


                    <div class="sr-lmt-basketball-pitch__half-pitch srm-is-left">
                      <div class="sr-lmt-basketball-pitch__free-throw-wrap srm-is-left">
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-border sr-lmt-basketball-pitch__line-opacity srm-is-top srm-left-edge srm-is-transparent"
                            style="border-color: rgb(255, 255, 255);"></div>
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-lane sr-lmt-basketball-pitch__line-opacity srm-left-edge srm-is-transparent"
                            style="border-color: rgb(255, 255, 255);"></div>
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-border sr-lmt-basketball-pitch__line-opacity srm-is-btm srm-left-edge srm-is-transparent"
                            style="border-color: rgb(255, 255, 255);"></div>
                      </div>
                      <div class="sr-lmt-basketball-pitch__rocket">
                        <div
                            class="sr-lmt-basketball-pitch__threept-line sr-lmt-basketball-pitch__side-edge sr-lmt-basketball-pitch__line-opacity srm-is-transparent srm-left-edge"
                            style="border-color: rgb(255, 255, 255);"></div>
                        <div
                            class="sr-lmt-basketball-pitch__circle-dashed-holder sr-lmt-basketball-pitch__line-opacity srm-is-transparent srm-left-edge"
                            style="border-color: rgb(255, 255, 255);">
                          <div class="sr-lmt-basketball-pitch__dash-el-holder srm-is-0">
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-top">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-left-edge srm-is-top"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-btm">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-left-edge srm-is-btm"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                          </div>
                          <div class="sr-lmt-basketball-pitch__dash-el-holder srm-is-1">
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-top">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-left-edge srm-is-top"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-btm">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-left-edge srm-is-btm"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                          </div>
                          <div class="sr-lmt-basketball-pitch__dash-el-holder srm-is-2">
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-top">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-left-edge srm-is-top"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-btm">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-left-edge srm-is-btm"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                          </div>
                          <div class="sr-lmt-basketball-pitch__dash-el-holder srm-is-3">
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-top">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-left-edge srm-is-top"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-btm">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-left-edge srm-is-btm"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                          </div>
                        </div>
                        <div
                            class="sr-lmt-basketball-pitch__circle-holder sr-lmt-basketball-pitch__line-opacity srm-is-transparent srm-left-edge"
                            style="border-color: rgb(255, 255, 255);">
                          <div class="sr-lmt-basketball-pitch__circle srm-is-transparent srm-left-edge"
                               style="border-color: rgb(255, 255, 255);"></div>
                        </div>
                        <div class="sr-lmt-basketball-pitch__threept-arc-holder srm-is-transparent srm-left-edge"
                             style="border-color: rgb(255, 255, 255);">
                          <div
                              class="sr-lmt-basketball-pitch__threept-circle sr-lmt-basketball-pitch__line-opacity srm-is-transparent srm-left-edge"
                              style="border-color: rgb(255, 255, 255);"></div>
                        </div>
                      </div>
                    </div>


                    <div class="sr-lmt-basketball-pitch__half-pitch srm-is-right">
                      <div class="sr-lmt-basketball-pitch__free-throw-wrap srm-is-right">
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-border sr-lmt-basketball-pitch__line-opacity srm-is-top srm-right-edge srm-is-transparent"
                            style="border-color: rgb(255, 255, 255);"></div>
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-lane sr-lmt-basketball-pitch__line-opacity srm-right-edge srm-is-transparent"
                            style="border-color: rgb(255, 255, 255);"></div>
                        <div
                            class="sr-lmt-basketball-pitch__free-throw-border sr-lmt-basketball-pitch__line-opacity srm-is-btm srm-right-edge srm-is-transparent"
                            style="border-color: rgb(255, 255, 255);"></div>
                      </div>
                      <div class="sr-lmt-basketball-pitch__rocket">
                        <div
                            class="sr-lmt-basketball-pitch__threept-line sr-lmt-basketball-pitch__side-edge sr-lmt-basketball-pitch__line-opacity srm-is-transparent srm-right-edge"
                            style="border-color: rgb(255, 255, 255);"></div>
                        <div
                            class="sr-lmt-basketball-pitch__circle-dashed-holder sr-lmt-basketball-pitch__line-opacity srm-is-transparent srm-right-edge"
                            style="border-color: rgb(255, 255, 255);">
                          <div class="sr-lmt-basketball-pitch__dash-el-holder srm-is-0">
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-top">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-right-edge srm-is-top"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-btm">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-right-edge srm-is-btm"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                          </div>
                          <div class="sr-lmt-basketball-pitch__dash-el-holder srm-is-1">
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-top">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-right-edge srm-is-top"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-btm">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-right-edge srm-is-btm"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                          </div>
                          <div class="sr-lmt-basketball-pitch__dash-el-holder srm-is-2">
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-top">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-right-edge srm-is-top"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-btm">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-right-edge srm-is-btm"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                          </div>
                          <div class="sr-lmt-basketball-pitch__dash-el-holder srm-is-3">
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-top">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-right-edge srm-is-top"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                            <div class="sr-lmt-basketball-pitch__dash-el srm-is-btm">
                              <div
                                  class="sr-lmt-basketball-pitch__circle-dash srm-is-transparent srm-right-edge srm-is-btm"
                                  style="border-color: rgb(255, 255, 255);"></div>
                            </div>
                          </div>
                        </div>
                        <div
                            class="sr-lmt-basketball-pitch__circle-holder sr-lmt-basketball-pitch__line-opacity srm-is-transparent srm-right-edge"
                            style="border-color: rgb(255, 255, 255);">
                          <div class="sr-lmt-basketball-pitch__circle srm-is-transparent srm-right-edge"
                               style="border-color: rgb(255, 255, 255);"></div>
                        </div>
                        <div class="sr-lmt-basketball-pitch__threept-arc-holder srm-is-transparent srm-right-edge"
                             style="border-color: rgb(255, 255, 255);">
                          <div
                              class="sr-lmt-basketball-pitch__threept-circle sr-lmt-basketball-pitch__line-opacity srm-is-transparent srm-right-edge"
                              style="border-color: rgb(255, 255, 255);"></div>
                        </div>
                      </div>
                    </div>


                    <div
                        class="sr-lmt-basketball-pitch__center-circle sr-lmt-basketball-pitch__line-opacity srm-is-transparent"
                        style="border-color: rgb(255, 255, 255);"></div>
                    <div class="sr-lmt-basketball-pitch__midcourt-line sr-lmt-basketball-pitch__line-opacity"
                         style="border-color: rgb(255, 255, 255);"></div>
                    <div
                        class="sr-lmt-basketball-pitch__boundary-line sr-lmt-basketball-pitch__line-opacity srm-is-transparent"
                        style="border-color: rgb(255, 255, 255);"></div>
                  </div>


                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 568 352">
                    <g class="lmt-basketball-pitch-baskets-svg">
                      <circle class="lmt-basketball-pitch-svg-stroke" fill="none" stroke-width="1" cx="36.91" cy="176"
                              r="8" style="stroke: rgb(255, 255, 255);"></circle>
                      <rect width="1" height="2" fill="none" stroke-width="2" x="27" y="175"
                            style="stroke: rgb(255, 255, 255);"></rect>
                      <rect class="lmt-basketball-pitch-svg-stroke" width="3" height="40" fill="none" x="23" y="156"
                            style="stroke: rgb(255, 255, 255);"></rect>
                      <circle class="lmt-basketball-pitch-svg-stroke" fill="none" stroke-width="1" cx="531.08" cy="176"
                              r="8" style="stroke: rgb(255, 255, 255);"></circle>
                      <rect width="1" height="2" fill="none" stroke-width="2" x="540" y="175"
                            style="stroke: rgb(255, 255, 255);"></rect>
                      <rect class="lmt-basketball-pitch-svg-stroke" width="3" height="40" fill="none" x="542" y="156"
                            style="stroke: rgb(255, 255, 255);"></rect>
                    </g>
                  </svg>
                </div>
                <div class="lmt-state">
                </div>
              </div>
            </div>
          </div>
        </div>
      </v-col>
      <!--EVENTS-->
      <v-col cols="12" md="6">
        <event-monitor
            :all-events="allEvents"
            :filtered-events="events"
            :selected-event="selectedEvent"
            :match-status="matchStatus"
            :match-time="matchTime"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";
import EventManager from "@/components/fv-lmt-basketball/utils/eventManager";
import * as dayjs from "dayjs";
import EventMonitor from "@/components/fv-lmt-basketball/components/EventMonitor";
import PositionIndicators from "@/components/fv-lmt-basketball/components/PositionIndicators";

export default {
  name: "FVLmtBasketball",
  components: {PositionIndicators, EventMonitor},
  data() {
    return {
      overlay: false,
      //match
      match: null,
      matchTeam: null,
      //match details
      statsCardsUpInfo:null,
      matchDetails: null,
      matchStatus: null,
      homeFoul: null,
      awayFoul: null,
      matchTime: -1,
      //match events
      selectedEvent: null,
      events: [],
      allEvents: [],
      //interval holder
      interval: null,
      //timeout default = 4000ms
      timeout: 1000,
      eventManager: new EventManager(),
    }
  },
  mounted() {
    this.fetchFeed();
    setInterval(() => {
      this.fetchDetails();
    }, 1000)
    this.interval = setInterval(() => {
      this.fetchFeed();
    }, this.timeout);
  },

  methods: {
    async fetchFeed() {
      const response = await axios.get(`https://lmt.fn.sportradar.com/common/tr/Etc:UTC/gismo/match_timelinedelta/${this.matchId}`);
      let match = response.data?.doc[0]?.data?.match;
      let allEvents = response.data?.doc[0]?.data?.events
      this.matchTeam = match;
      this.eventManager.resolve(match, allEvents)
      this.matchStatus = this.eventManager.status
      this.matchTime = this.eventManager.time
      this.selectedEvent = this.eventManager.selectedEvent
      this.events = this.eventManager.specialEvents
      this.allEvents = response.data?.doc[0]?.data?.events
      //this.matchStatus = getMatchStatus(response.data?.doc[0]?.data.match.status._id, response.data?.doc[0]?.data?.events, this.matchTime)
      //this.handleNewEventList(response.data?.doc[0]?.data?.events);
    },

    async fetchDetails() {
      const response = await axios.get(`https://lmt.fn.sportradar.com/demolmt/en/Etc:UTC/gismo/match_detailsextended/${this.matchId}`);
      let info = response.data?.doc[0]?.data;
      this.matchDetails = info.values;
      this.homeFoul = this.matchDetails[1182].value.home.replace("\\", "").split("/")[this.matchTeam.p - 1];
      this.awayFoul = this.matchDetails[1182].value.away.replace("\\", "").split("/")[this.matchTeam.p - 1];
      this.fetchStatsCardUpInfo();
    },
    async fetchStatsCardUpInfo() {
      const data = await axios.get(`https://widgets.fn.sportradar.com/demolmt/en/Etc:UTC/gismo/match_info/${this.matchId}`)
      this.statsCardsUpInfo = data.data?.doc[0]?.data
    }
  },

  computed: {
    matchId() {
      return this.$route.params.id;
    },
  },

  filters: {
    formatDateTime: function (value) {
      return dayjs.unix(value).format('DD/MM/YYYY - HH:mm:ss');
    },
    formatSeconds: function (value) {
      if (value === -1) return null;
      return `${(value / 60).toFixed(0)}:${(value % 60)}`
    }
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
}
</script>

<style scoped>

.set-info-section {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 8%;
  width: 100%;
}

.set-info-container {
  display: flex;
  justify-content: center;
  width: 40%;
}

.set-info {
  display: flex;
  justify-content: center;
  place-items: center;
  background-color: white;
  font-size: 16px;
  font-weight: 300;
  margin: 0 10px;
  width: 50px;
  height: 20px;
  z-index: 3;
}

.foul-info-container {
  display: flex;
  justify-content: center;
  place-items: center;
  background-color: white;
  padding: 2px 4px;
  font-size: 13px;
  font-weight: 300;
  max-width: 70px;
  height: 18px;
  z-index: 3;
}

.lmt-wrapper {
  position: relative;
}

.lmt-pitch {
  width: 100%;
  height: 100%;
  background: #f2a950;
}

.lmt-pitch-side-area {
  background-color: #0072b1;
}

.lmt-basketball-pitch-bo-l {
  position: absolute;
  left: 0;
  right: 0;
  height: 7.32325%;
}

.lmt-basketball-pitch-bo-s {
  position: absolute;
  bottom: 7.32325%;
  top: 7.32325%;
  width: 1.25%;
}

.lmt-basketball-pitch-bo-l.srm-is-top {
  top: 0;
}

.lmt-basketball-pitch-bo-l.srm-is-btm {
  bottom: 0;
}

.lmt-basketball-pitch-bo-s.srm-is-left {
  left: 0;
}

.lmt-basketball-pitch-bo-s.srm-is-right {
  right: 0;
}

.lmt-basketball-pitch-baskets-svg {
  opacity: .5;
}

.lmt-basketball-pitch-svg-stroke {
  stroke-width: 1.5;
}

.lmt-basketball-pitch-texture {
  background-size: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-repeat: repeat;
}

.lmt-pitch-color {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}


.sr-lmt-basketball-pitch__pitch-dimensions {
  position: absolute;
  left: 1.25%;
  right: 1.25%;
  top: 7.32325%;
  bottom: 7.32325%;
}

.sr-lmt-basketball-pitch__line-opacity {
  opacity: .4;
}

.sr-lmt-basketball-pitch__midcourt-line {
  position: absolute;
  left: 0;
  right: 0;
  margin: 2px auto;
  top: 0;
  bottom: 0;
  width: 2px;
  border-right-style: solid;
  border-right-width: 2px;
}

.sr-lmt-basketball-pitch__boundary-line {
  border-width: 2px;
  border-style: solid;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.srm-is-transparent {
  background: transparent !important;
}

.sr-lmt-basketball-pitch__center-circle {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 12.821%;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  height: 23.67%;
}

.sr-lmt-basketball-pitch__half-pitch {
  position: absolute;
  top: 0;
  bottom: 0;
  height: 100%;
  width: 50%;
}

.sr-lmt-basketball-pitch__free-throw-wrap {
  direction: ltr;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 100%;
}

.sr-lmt-basketball-pitch__free-throw-wrap.srm-is-right {
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  margin-right: 2px;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-right-edge {
  border-left-style: solid;
  border-left-width: 2px;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-is-top {
  border-top-style: solid;
  border-top-width: 2px;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-is-btm {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.sr-lmt-basketball-pitch__free-throw-border {
  width: 41.346%;
  height: 4%;
}


.sr-lmt-basketball-pitch__free-throw-lane.srm-right-edge {
  border-right: none;
}

.sr-lmt-basketball-pitch__free-throw-lane {
  top: 38%;
  bottom: 38%;
  height: 24%;
  width: 41.346%;
  border-style: solid;
  border-width: 2px;
}

.sr-lmt-basketball-pitch__threept-line {
  top: 9%;
  bottom: 9%;
  height: 82%;
  width: 25%;
  border-top-style: solid;
  border-top-width: 2px;
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.sr-lmt-basketball-pitch__side-edge {
  position: absolute;
}

.sr-lmt-basketball-pitch__side-edge.srm-right-edge {
  margin-right: 2px;
  right: 0;
}

.sr-lmt-basketball-pitch__circle-dashed-holder {
  position: absolute;
  top: 38%;
  bottom: 38%;
  height: 24%;
  width: 12.821%;
  overflow: hidden;
}

.sr-lmt-basketball-pitch__circle-dashed-holder.srm-right-edge {
  right: 28.5255%;
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.sr-lmt-basketball-pitch__dash-el-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
}

.sr-lmt-basketball-pitch__dash-el-holder.srm-is-0 {
  -webkit-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sr-lmt-basketball-pitch__dash-el-holder.srm-is-1 {
  -webkit-transform: rotate(20deg);
  transform: rotate(20deg);
}

.sr-lmt-basketball-pitch__dash-el-holder.srm-is-2 {
  -webkit-transform: rotate(-20deg);
  transform: rotate(-20deg);
}

.sr-lmt-basketball-pitch__dash-el-holder.srm-is-3 {
  -webkit-transform: rotate(-60deg);
  transform: rotate(-60deg);
}

.sr-lmt-basketball-pitch__dash-el {
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -8%;
  width: 100%;
  height: 8%;
  -webkit-transform-origin: 100% 50%;
  transform-origin: 100% 50%;
  overflow: hidden;
}

.sr-lmt-basketball-pitch__dash-el.srm-is-top {
  -webkit-transform: skew(-4deg, -4deg);
  transform: skew(-4deg, -4deg);
}

.sr-lmt-basketball-pitch__circle-dash {
  position: absolute;
  width: 200%;
  height: 1250%;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
  left: 0;
}

.sr-lmt-basketball-pitch__circle-dash.srm-is-top {
  top: -575%;
  -webkit-transform: skew(4deg, 4deg);
  transform: skew(4deg, 4deg);
}

.sr-lmt-basketball-pitch__dash-el.srm-is-btm {
  -webkit-transform: skew(4deg, 4deg);
  transform: skew(4deg, 4deg);
}


.sr-lmt-basketball-pitch__circle-dash.srm-is-btm {
  top: -575%;
  -webkit-transform: skew(-4deg, -4deg);
  transform: skew(-4deg, -4deg);
}

.sr-lmt-basketball-pitch__threept-arc-holder {
  position: absolute;
  top: 9%;
  bottom: 9%;
  height: 82%;
  width: 33.658%;
  overflow: hidden;
}

.sr-lmt-basketball-pitch__threept-arc-holder.srm-right-edge {
  right: 25%;
  margin-right: 2px;
}

.sr-lmt-basketball-pitch__circle {
  position: absolute;
  width: 200%;
  height: 100%;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
}

.sr-lmt-basketball-pitch__circle.srm-right-edge {
  left: 0;
}

.sr-lmt-basketball-pitch__threept-circle.srm-right-edge {
  left: 2px;
}

.sr-lmt-basketball-pitch__threept-circle {
  position: absolute;
  width: 276%;
  top: -2.184%;
  bottom: -2.184%;
  border-radius: 50%;
  border-width: 2px;
  border-style: solid;
}

.sr-lmt-basketball-pitch__circle-holder {
  position: absolute;
  top: 38%;
  bottom: 38%;
  height: 24%;
  width: 12.821%;
  overflow: hidden;
}

.sr-lmt-basketball-pitch__circle-holder.srm-right-edge {
  right: 41.346%;
}


.sr-lmt-basketball-pitch__half-pitch.srm-is-left {
  left: 0;
}

.sr-lmt-basketball-pitch__half-pitch.srm-is-right {
  right: 0;
}


.sr-lmt-basketball-pitch__free-throw-wrap.srm-is-left {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  margin-left: 2px;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-left-edge {
  border-right-style: solid;
  border-right-width: 2px;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-is-top {
  border-top-style: solid;
  border-top-width: 2px;
}

.sr-lmt-basketball-pitch__free-throw-lane.srm-left-edge {
  border-left: none;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-is-btm {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.sr-lmt-basketball-pitch__threept-arc-holder.srm-left-edge {
  left: 25%;
  margin-left: 2px;
}

.sr-lmt-basketball-pitch__threept-circle.srm-left-edge {
  right: 2px;
}

.sr-lmt-basketball-pitch__side-edge.srm-left-edge {
  margin-left: 2px;
  left: 0;
}

.sr-lmt-basketball-pitch__circle-holder.srm-left-edge {
  left: 41.346%;
}

.sr-lmt-basketball-pitch__circle.srm-left-edge {
  right: 0;
}

.sr-lmt-basketball-pitch__circle-dashed-holder.srm-left-edge {
  left: 28.5255%;
}


.sr-lmt-basketball-pitch__free-throw-border.srm-def-bg-clr {
  background-color: #0072b1;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-branding {
  border-color: transparent;
  background-clip: padding-box;
}

.sr-bb .sr-lmt-basketball-pitch__free-throw-border.srm-left-edge {
  border-right-style: solid;
  border-right-width: 2px;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-is-top {
  border-top-style: solid;
  border-top-width: 2px;
}

.sr-lmt-basketball-pitch__free-throw-border.srm-is-btm {
  border-bottom-style: solid;
  border-bottom-width: 2px;
}

.sr-lmt-basketball-pitch__free-throw-lane.srm-branding {
  border-color: transparent;
  background-clip: padding-box;
}

.sr-lmt-basketball-pitch__free-throw-lane.srm-left-edge {
  border-left: none;
}

.sr-lmt-basketball-pitch__free-throw-lane {
  top: 38%;
  bottom: 38%;
  height: 24%;
  width: 41.346%;
  border-style: solid;
  border-width: 2px;
}


.fv-lmt-content-wrapper {
  position: absolute;
  left: 2%;
  right: 2%;
  top: 8%;
  bottom: 8%;
  z-index: 2;
}


</style>
