<template>
  <div class="position-container">
    <div class="home-rebound-section blink" v-if="selectedEvent.team === 'home'">
      <div class="rebound-container">

        <div class="jersery">
        </div>

        <div class="rebound-info">
          <div class="rebounds">
            <div class="rebound" :class="selectedEvent.team === 'away' ? 'away-rebound' : 'home-rebound'">
              REBOUND
            </div>
            <hr :class="selectedEvent.team === 'away' ? 'away-rebound' : 'home-rebound'">
            <div class="team-name">
              {{matchTeam.teams[selectedEvent.team].name}}
            </div>
          </div>

          <div class="rebound-count">
            <span> {{matchDetails[1067].value.home}} </span>
            <span v-if="matchDetails[1067].value.home === 1" class="rebound-string">REBOUND</span>
            <span v-else class="rebound-string">REBOUNDS</span>
          </div>

        </div>
      </div>
    </div>

    <div class="away-rebound-section blink" v-if="selectedEvent.team === 'away'">
      <div class="rebound-container">

        <div class="jersery">
        </div>

        <div class="rebound-info">
          <div class="rebounds">
            <div class="rebound" :class="selectedEvent.team === 'away' ? 'away-rebound' : 'home-rebound'">
              REBOUND
            </div>
            <hr :class="selectedEvent.team === 'away' ? 'away-rebound' : 'home-rebound'">
            <div class="team-name">
              {{matchTeam.teams[selectedEvent.team].name}}
            </div>
          </div>

          <div class="rebound-count">
            <span> {{matchDetails[1067].value.away}} </span>
            <span v-if="matchDetails[1067].value.away === 1" class="rebound-string">REBOUND</span>
            <span v-else class="rebound-string">REBOUNDS</span>
          </div>

        </div>
      </div>
    </div>

  </div>
</template>

<script>

export default {
  name: "ReboundPosition",
  props: {
    selectedEvent: Object,
    matchDetails: Object,
    matchTeam: Object
  }
}

</script>

<style scoped>

.blink {
  animation: reveal .5s forwards;
}

@keyframes reveal {
  from {
    clip-path: inset(0 0 100% 100%);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

hr {
  height: 1px;
  margin: 2px 0 0 0;
  border: none;
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.home-rebound-section {
  position: absolute;
  top: 40%;
  left: 70%;
  place-items: center;
  width: 100%;
}

.away-rebound-section {
  position: absolute;
  top: 40%;
  left: 10%;
  place-items: center;
  width: 100%;
}

.rebound-container {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 180px;
  height: 88px;
  background-color: white;
}

.jersery {
  display: flex;
  justify-content: center;
  place-items: center;
  width: 50px;
  height: 100px;
}

.rebound-info {
  display: flex;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  height: 90%;
}

.rebound {
  display: flex;
  flex-direction: column;
  border-radius: 3px;
  color: white;
  font-weight: 500;
  width: fit-content;
  padding: 0 5px;
}

.home-rebound {
  background-color: #002171;
}

.away-rebound {
  background-color: red;
}

.team-name {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 12px;
  font-weight: 300;
}

.rebound-count {
  display: flex;
  flex-direction: column;
}

.rebound-string {
  font-weight: 300;
}


</style>