<template>
  <div class="position-container">
    <div v-if="selectedEvent.team === 'away'" class="away-ball">
      <img class="ball" src="https://img.icons8.com/emoji/24/000000/basketball-emoji.png"/>
    </div>

    <div v-if="selectedEvent.team === 'home'" class="home-ball">
      <img class="ball" src="https://img.icons8.com/emoji/24/000000/basketball-emoji.png"/>
    </div>
  </div>
</template>

<script>
export default {
  name: "FreeThrowsAwarded",
  props: {
    selectedEvent: Object
  }
}
</script>

<style scoped>

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
}

.away-ball {
  display: flex;
  place-items: center;
  position: absolute;
  left: 19%;
  width: 100%;
  height: 100%;
}

.home-ball {
  display: flex;
  place-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 79%;
}

.ball {
  animation: bounce .5s infinite alternate;
  -webkit-animation: bounce .5s infinite alternate;
}
@keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-50px);
  }
}
@-webkit-keyframes bounce {
  from {
    transform: translateY(0px);
  }
  to {
    transform: translateY(-50px);
  }
}
</style>