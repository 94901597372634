<template>
  <div class="position-container">
    <div class="delay-card-container" v-if="matchTeam.status._id === 0 || matchTeam.status._id === 30 || selectedEvent.type === 'timeout' || matchTeam.status._id === 100">
      <statistics v-if="selectedEvent._id !== 941987030" :matchTeam="matchTeam" :selectedEvent="selectedEvent" :match-details="matchDetails" :statsCardsUpInfo="statsCardsUpInfo"></statistics>
      <half-time-and-ended v-if="matchTeam.status._id === 30 || matchTeam.status._id === 100"></half-time-and-ended>
    </div>

    <div class="position-container" v-if="matchTeam.status._id !== 30 && matchTeam.status._id !== 100">
      <attack-position v-if="this.type === 'possession'" :selectedEvent="selectedEvent" :match-team="matchTeam"></attack-position>
      <foul-position v-if="this.type === 'foul'" :selectedEvent="selectedEvent" :matchTeam="matchTeam" :match-details="matchDetails"></foul-position>
      <goal-position v-if="this.type === 'goal' " :selectedEvent="selectedEvent" :match-team="matchTeam" :match-details="matchDetails"></goal-position>
      <rebound-position v-if="this.type === 'rebound' " :selectedEvent="selectedEvent" :match-details="matchDetails" :match-team="matchTeam"></rebound-position>
      <missed-position v-if="this.type === 'attempt_missed' " :selectedEvent="selectedEvent" :match-team="matchTeam"></missed-position>
      <timeout-position v-if="this.type === 'timeout' " :selectedEvent="selectedEvent" :match-team="matchTeam"></timeout-position>
      <free-throws-awarded v-if="this.type === 'free_throws_awarded'" :selectedEvent="selectedEvent" ></free-throws-awarded>
    </div>
  </div>
</template>

<script>
import AttackPosition from "@/components/fv-lmt-basketball/components/Positions/AttackPosition";
import FoulPosition from "@/components/fv-lmt-basketball/components/Positions/FoulPosition";
import GoalPosition from "@/components/fv-lmt-basketball/components/Positions/GoalPosition";
import ReboundPosition from "@/components/fv-lmt-basketball/components/Positions/ReboundPosition";
import MissedPosition from "@/components/fv-lmt-basketball/components/Positions/MissedPosition";
import TimeoutPosition from "@/components/fv-lmt-basketball/components/Positions/TimeoutPosition";
import FreeThrowsAwarded from "@/components/fv-lmt-basketball/components/Positions/FreeThrowsAwarded";
import Statistics from "./Statistics";
import HalfTimeAndEnded from "./HalfTimeAndEnded";

export default {
  name: "PositionIndicators",
  components: {
    AttackPosition,
    FoulPosition,
    GoalPosition,
    ReboundPosition,
    MissedPosition,
    TimeoutPosition,
    FreeThrowsAwarded,
    Statistics,
    HalfTimeAndEnded
  },
  data() {
    return {
      type: null
    }
  },
  props: {
    selectedEvent: Object,
    matchTeam: Object,
    matchDetails: Object,
    statsCardsUpInfo: Object
  },
  methods: {
    handleEventChange(value) {
      if (!value) {
        return
      }

      const type = value?.type;

      if (type) {
        this.type = type;
      }

    }
  },
  watch: {
    selectedEvent(value) {
      this.handleEventChange(value)
    }
  }
}
</script>

<style scoped>

.delay-card-container {
  position: absolute;
  display: flex;
  place-items: center;
  width: 100%;
  height: 100%;
  animation: 6s fadeIn;
  animation-fill-mode: forwards;
  visibility: hidden;
}

@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}

.position-container {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

</style>
