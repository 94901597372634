<template>
  <div class="scoring-stats-section">
    <div class="general-container">

      <div class="scoring-statistics">
        <div class="scoring-statistics-section">
          <div>
            SCORING STATISTICS
          </div>
        </div>

        <hr>
      </div>

      <div class="info-container">

        <!-- FREE THROWS -->

        <div class="number-container">
          <span class="home-number">{{this.homeThrow}}</span>
          <span class="font-size" style="font-weight: 300">FREE THROWS</span>
          <span class="away-number">{{this.awayThrow}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: this.homeThrow}">
          </div>
          <div class="away-percent" :style="{flex: this.awayThrow}">
          </div>
        </div>

        <!-- 2 POINT -->

        <div class="number-container">
          <span class="home-number">{{this.homeTwoPoint}}</span>
          <span class="font-size" style="font-weight: 300">2 POINT SHOTS</span>
          <span class="away-number">{{this.awayTwoPoint}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: this.homeTwoPoint}">
          </div>
          <div class="away-percent" :style="{flex: this.awayTwoPoint}">
          </div>
        </div>

        <!-- 3 POINT -->

        <div class="number-container">
          <span class="home-number">{{this.homeThreePoint}}</span>
          <span class="font-size" style="font-weight: 300">3 POINT SHOTS</span>
          <span class="away-number">{{this.awayThreePoint}}</span>
        </div>

        <div class="home-percent">
          <div class="progress" :style="{flex: this.homeThreePoint}">
          </div>
          <div class="away-percent" :style="{flex: this.awayThreePoint}">
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: "ScoringStats",
  props: {
    matchDetails: Object,
    matchTeam: Object
  },
  data() {
    return {
      homeThrow: this.matchDetails[1065].value.home.replace("\\" , "").split("/")[0],
      awayThrow: this.matchDetails[1065].value.away.replace("\\" , "").split("/")[0],
      homeTwoPoint: this.matchDetails.twopointers.value.home.replace("\\" , "").split("/")[0],
      awayTwoPoint: this.matchDetails.twopointers.value.away.replace("\\" , "").split("/")[0],
      homeThreePoint: this.matchDetails.threepointers.value.home.replace("\\" , "").split("/")[0],
      awayThreePoint: this.matchDetails.threepointers.value.away.replace("\\" , "").split("/")[0],
    }
  }
}
</script>

<style scoped>

hr {
  height: 1.5px;
  background-color: #dedede;
  border: none;
}

.scoring-statistics {
  display: flex;
  flex-direction: column;
}

.scoring-stats-section {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.general-container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  z-index: 2;
}

.scoring-statistics-section {
  font-weight: bolder;
  font-size: 12px;
  padding: 10px 0 3px 0;
}

.info-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  padding: 10px 6px;
}

.number-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
}

.home-percent{
  display: flex;
  place-items: center;
  justify-content: space-between;
  width: 100%;
}

.progress{
  display: flex;
  height: 3px;
  background: darkblue;
}

.away-percent {
  display: flex;
  height: 3px;
  background: red;
}

.home-number {
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 12px;
  color: darkblue;
}

.away-number {
  display: flex;
  justify-content: center;
  place-items: center;
  font-size: 12px;
  color: red;
}

</style>